@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// copied from tailwind
$breakpoints: (
  'sm':  640px,
  'md': 768px,
  'lg':  1024px,
  'xl':  1280px,
  '2xl':  1536px
);

.App {
  /* text-align: center; */
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/*
|--------------------------------------------------------------------------
| Sidebar override
|--------------------------------------------------------------------------
*/
$sidebar-bg-color: #fff;
$highlight-color: #fff;
$breakpoint-md: 768px; // for sidebar

@import '~react-pro-sidebar/dist/scss/styles.scss';

//        <aside className="min-h-screen sticky top-0 flex-initial basis-1/5 shrink-0 bg-white shadow-md flex flex-col">

.sidebar-toggle-btn {
  cursor: pointer;
  /*
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  */
  display: none;
}

.sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .sidebar-btn {
    transition: width 0.3s;
    width: 150px;
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      margin-left: 5px;
      font-size: 13px;
    }
    &:hover {
      color: $highlight-color;
    }
  }
}

.pro-inner-item {
  padding: 0 !important;
}

@media (max-width: $breakpoint-md) {
  .sidebar-toggle-btn {
    display: flex;
  }
}

.pro-menu-item.active {
  @apply bg-green-500/100 text-white;
}
.pro-icon-wrapper {
  .pro-icon {
    animation: none !important;
  }
}

// make link work for entire cell
.pro-menu-item {
  position: relative;
  > .pro-inner-item {
    position: unset !important;
  }
}
